import { PropOptions } from 'vue'
import { TType, TColor } from './types'

const props = {
  type: {
    type: String,
    default: 'h1',
  } as PropOptions<TType>,
  htmlTag: {
    type: String,
  } as PropOptions<TType>,
  color: {
    type: String,
    default: 'primary',
  } as PropOptions<TColor>,
  light: Boolean,
  underline: Boolean,
  isParagraph: Boolean,
  leadingNone: Boolean,
  skeleton: Boolean,
  skeletonWidth: {
    type: String,
    default: 'full',
  },
}

export default props
